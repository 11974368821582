import React from 'react';

import '../../../src/index.css'

import Collection from '../Utils/Collections';
import PageSections from '../Utils/PageSections';

import '../Css/TailsPrueba.css'

import SectionImgTextHome from '../SectionImgTextHome';
import HeroSectionsHome from '../HeroSectionsHome';

import Heading from '../Utils/Heading';
import MetaData from '../layout/MetaData';





function Home() {
        
        

 
    return (
      
        <> <MetaData title={'Best Place for you Windshield'} />
        
      
             <main>
             
             <div class="relative content-center  bg-no-repeat bg-cover heading " >     
     <Heading 
        title="Welcome!"
        slogan="We are thrilled to be able to assist you."
        boton= "Get Started"/> 
    
     </div>

     
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 sliderInit">
    

           <div className='heroSection '> 
           
            <SectionImgTextHome  />
    
             
           
            </div>
            </div>

            <div className=' bg-gray-200 mb-5'>
            <div className="  max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 sliderInit">
            <HeroSectionsHome />
            
            </div>
            </div>
           
        
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 sliderInit">
            
            <PageSections />
            <Collection />

           
          </div>
        
        </main>

        

        </>
    )
}

export default Home;