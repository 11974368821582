
import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'


import { FaAward, 
         FaCalendarDay, 
         FaCarSide, 
         FaCcMastercard, 
         FaCertificate, 
         FaClock, 
         FaFileContract, 
         FaFileInvoice, 
         FaInfo, 
         FaKeyboard, 
         FaLaptopCode,  
         FaMoneyBill,  
         FaMoneyCheckAlt,  
         FaPhoneAlt, 
         FaRegCalendarCheck, 
         FaRegWindowRestore, 
         FaShippingFast, 
         FaStar, 
         FaTools, 
         FaTruck,
         FaWarehouse  } from 'react-icons/fa'
import { Link, useParams } from 'react-router-dom'
import MetaData from '../../Components/layout/MetaData';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import gtmApi from '../../api/gtmApi';





const CardCollaborators = ({ collaborator }) => {

  let [isOpen, setIsOpen] = useState(false)
  const [buttonState, setButtonState] = useState("default");
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  let [isOpenForm, setIsOpenForm] = useState(false)

  function closeModalForm() {
    setIsOpenForm(false)
  }

  function openModalForm() {
    setIsOpenForm(true)
  }

 // PRUEBA DE UNA LLAMADA CON TWILIO
 const { id } = useParams();
 //const [collaboratorId, setCollaboratorId] = useState();

 const [name, setName] = useState('');
 const [phone, setPhone] = useState('');
 const [message, setMessage] = useState('');
 const [callSid, setCallSid] = useState(null);
 const [duration, setDuration] = useState(null);
 const [errorMessage, setErrorMessage] = useState('');
 
// ******* LLAMADA CON VONAGE ******
const handleCallVonage = async (e) => {
  e.preventDefault();

  const data = { name, phone };
  try {
    const res = await gtmApi.post(`/api/v1/collaborator/${collaborator._id}/callv`, data);
    setCallSid(res.data.callSid);
    setMessage(`Call started successfully.`);
  } catch (error) {
    console.error(error);
    setMessage('Failed to start the call. Please try again.');
  }
};

const handleEndCallV = async () => {
  try {
    await gtmApi.delete(`/api/v1/close-call/${callSid}`);
    setDuration('Call ended');
  } catch (error) {
    console.error(error);
    setMessage('Failed to end the call. Please try again.');
  }
};
// ******* LLAMADA CON TWILIO ******
 const handleSubmitCall = async (e) => {
  e.preventDefault();

  const data = { name, phone };
  try {
    const res = await gtmApi.post('/api/v1/calls', data);
    setMessage(`Call started successfully.`);
    // setMessage(`Call started successfully. El ID de la llamada es ${res.data}.`);
  } catch (error) {
    console.error(error);
    setMessage('Failed to start the call. Please try again.');
  }
};

const handleStartCall = async () => {
  const data = { name, phone};
  if (!name || !phone) {
    setErrorMessage('Please, complete all fields.'); }
  try {
   
    const res = await gtmApi.post(`/api/v1/collaborator/${collaborator._id}/call`, data);
    setCallSid(res.data);
    setMessage(`Call started successfully.`);
    // setMessage(`Call started successfully. El ID de la llamada es ${res.data}.`);
    
  } catch (error) {
    console.error(error);
    setMessage('Failed to start the call. Please try again.');
  }
};

const handleEndCall = async () => {
  try {
    await gtmApi.delete(`/${callSid}`);
    setDuration('Call ended');
  } catch (error) {
    console.error(error);
    setMessage('Failed to start the call. Please try again.');
  }
};


// *********  FORM CAR QUOTE FREE *********
const [formImages, setFormImages] = useState([]);
const [cellphone, setCellphone] = useState('');
const [email, setEmail] = useState('');
const [address, setAddress] = useState('');
const [description, setDescription] = useState('');
const handleImageChange = (event) => {
  setFormImages(event.target.files);
 
};

const handleSubmit = async (e) => {
  e.preventDefault();

 
  const formData = new FormData();
  const formImagesArray = Array.from(formImages);
  formImagesArray.forEach((formImage) => {
    formData.append('FormImages', formImage);
  });

  // Verificar que cada campo tenga un valor
if (!name || !email || !cellphone || !address || !description) {
  alert('Please complete each of the fields.');
  //Swal.fire('Please complete each of the fields.');
  return;
}

  // Verificar que al menos una imagen se cargue
  if (formImagesArray.length === 0) {
    alert('Please upload at least one image');
    
    return;
  }
  setIsLoading(true)
  setFormSubmitted(false)

  formData.append('name', name);
  formData.append('email', email);
  formData.append('cellphone', cellphone);
  formData.append('address', address);
  formData.append('description', description);

  if (collaborator && collaborator.email) {
    formData.append('emailCollaborator', collaborator.email);
  }

  try { 
    const res = await gtmApi.post(`/api/v1/collaborator/${collaborator._id}/submit-form-car`, formData);
    console.log(res.data);
    setIsLoading(false)
    setFormSubmitted(true)
   // alert('Form sent successfully!');
  } catch (err) {
    console.error(err);
  }
};


    return (
     <> 
     <MetaData title={'Best Collaborators Online'} />                
     
      <div className=' rounded-lg mb-6 xs:md-6 sm:mb-6 lg:mb-8 mt-6 bg-white  justify-items-center '>
         
         {/* CALL TO IMAGE PERFIL & COMPANY NAME COLLABORATORS */}  
          <div className=" bg-slate-900 group mb-2  pb-1 rounded-t-lg">
           <div className="  justify-center grid  grid-flow-row auto-rows-auto " >
             <img
                  //  className="shadow-md -mt-16 h-32 w-32 object-top overflow-visible  hover:scale-150 "
                    src={collaborator.avatar[0].url}
                    alt="tu ta claro" 
                    className="-mt-16 h-32 w-32 rounded-full hover:scale-150"
                />
                  
             </div>
           </div>

        <div className=" justify-center mx-4 grid grid-flow-row auto-rows-max">
        <div className=" text-center space-y-2 sm:text-left">
          <p className="justify-center text-lg text-black font-sans font-bold hover:text-custom-red">
            <Link className='' to={`/collaborator/${collaborator._id}`}>{collaborator.name}</Link>
           </p>
        </div>


        </div>
              
        <div className=" border-b-gray-600 border-b-2 pb-2  justify-around mx-4 grid grid-flow-col auto-cols-max">
        <div className=" justify-start  grid grid-flow-col auto-cols-max">
       
           
           
           
           {/* REVIEWS */}


      {/* CALL TO RATINGS & REVIEWS COLLABORATORS */}  
        <p className='pl-2 font-sans text-sm text-black hover:text-red-700 mt-auto'>{(collaborator.ratings / 5) * 100}% </p>
        <FaStar className=' hover:text-red-700 text-yellow-500 h-5 w-5' />      
        </div>
                
           {/* <p className='text-sm text-yellow-500 hover:text-red-700 mt-auto'>{(collaborators.ratings / 5) * 100}%</p> */}
           <span className="font-sans text-md text-custom-red ml-2">{collaborator.numOfReviews} Reviews</span>
        </div>
             {/* CALL TO DESCRIPTION COLLABORATORS */}  
        <div className=" border-b-gray-600 border-b-2  py-4 mx-4 grid grid-flow-col auto-cols-auto">
            <p className='font-body text-sm sm:text-left md:text-justify xl:text-justify'>
            {collaborator.description} 
            </p>
        </div>


            {/* CALL TO PERFORMANCES COLLABORATORS */}               
  <div className="border-b-gray-600 border-b-2  py-4  justify-around pb-2 mx-4 grid grid-cols-3 grid-flow-row gap-2">
      
  {collaborator.performanceCollaborator && collaborator.performanceCollaborator.map( performanceCollaborator => [
           
          performanceCollaborator.oEMGlass ? (
          <div className=" h-4/5 w-5/5 mx-2 mt-5">
           <FaCarSide className='text-custom-red h-7 w-7'/>
           <p className='font-body text-sm '>   OEM glass </p>
          </div>
          ): (
            <></>
           
          ) ,
          performanceCollaborator.warranty ? (
            <div className=" h-4/5 w-5/5 mx-2 mt-5">
               <FaAward className=' text-custom-red h-7 w-7'/>
               <p className='font-body'>   Warranty </p>
              </div>
              ): (
                <></>
              ) ,

            performanceCollaborator.replacement ? (
              <div className=" h-4/5 w-5/5 mx-2 mt-5">
               <FaTools className=' text-custom-red h-7 w-7'/>
               <p className='font-body'> Replacement </p>
              </div>
              ): (
                <>  </>
              ),
           performanceCollaborator.insurance ? (
            <div className=" h-4/5 w-5/5 mx-2 mt-5">
            <FaFileContract className=' text-custom-red h-7 w-7'/>
               <p className='font-body'> Insurance </p>
              </div>
              ): (
                <> </>
              ),
            
           performanceCollaborator.repair ? (
            <div className=" h-4/5 w-5/5 mx-2 mt-5">
             <FaRegWindowRestore className=' text-custom-red h-7 w-7'/>
              <p className='font-body'> Repair </p>
            </div>
             ): (
              <></>
           ),
            performanceCollaborator.calibration ? (
             <div className=" h-4/5 w-5/5 mx-2 mt-5">
              <FaLaptopCode className=' text-custom-red h-7 w-7'/>
              <p className='font-body'> Calibration </p>
             </div>
             ): (
               <> </>
             ),
             performanceCollaborator.nextDayService ? (
               <div className=" h-4/5 w-5/5 mx-2 mt-5">
                <FaRegCalendarCheck className=' text-custom-red h-7 w-7'/>
                <p className='font-body'> Next Day Service </p>
               </div>
                 ): (
                   <> </>
                     ),

              performanceCollaborator.registerAGRSS ? (
                <div className=" h-4/5 w-5/5 mx-2 mt-5">
                 <FaCertificate className=' text-custom-red h-7 w-7'/>
                 <p className='font-body'> Regidter AGRSS </p>
                </div>
                ): (
                  <> </>
                ),
            
                performanceCollaborator.freeMobileService ? (
                  <div className=" h-4/5 w-5/5 mx-2 mt-5">
                   <FaShippingFast className=' text-custom-red h-7 w-7'/>
                   <p className='font-body'> Free Mobile Service </p>
                  </div>
                  ): (
                    <> </>
                  ),

                performanceCollaborator.localServices ? (
                  <div className=" h-4/5 w-5/5 mx-2 mt-5">
                   <FaWarehouse className=' text-custom-red h-7 w-7'/>
                   <p className='font-body'> Local Services </p>
                  </div>
                  ): (
                    <> </>
                  ),
                  performanceCollaborator.deliveryServices ? (
                    <div className=" h-4/5 w-5/5 mx-2 mt-5">
                     <FaTruck className=' text-custom-red h-7 w-7'/>
                     <p className='font-body'> Delivery Services </p>
                    </div>
                    ): (
                      <> </>
                    ),

            ])}   
    </div>

     
     {/* CALL TO PAYMENTS COLLABORATORS */}               
     <div className="border-b-gray-600 border-b-2  py-4  justify-around pb-2 mx-4 grid grid-cols-3 grid-flow-row gap-2">
      
     
      {collaborator.paymentMethod.cash ? (
        <div className=" h-4/5 w-5/5 mx-2 mt-5">
         <FaMoneyBill className='text-blue-700 h-9 w-9'/>
         <p className='font-body'> Cash </p>
        </div>
        ): (
          <></>
         
        ) }
        {collaborator.paymentMethod.card ? (
          <div className=" h-4/5 w-5/5 mx-2 mt-5">
           <FaCcMastercard className='text-blue-700 h-9 w-9'/>
           <p className='font-body'> Card </p>
          </div>
          ): (
            <></>
           
          )} 
          {collaborator.paymentMethod.check ? (
            <div className=" h-4/5 w-5/5 mx-2 mt-5">
             <FaMoneyCheckAlt className='text-blue-700 h-9 w-9'/>
             <p className='font-body'> Check </p>
            </div>
            ): (
              <></>
             
            )}
      
      
    </div> 
    
    
    {/* CALL TO SCHELUDE COLLABORATORS */}    
    <div className="border-b-gray-600 border-b-2  py-2  justify-around pb-2 mx-4 grid grid-cols-3 grid-flow-row gap-2">

      
        {collaborator.scheduleDays.mondayToSaturday ? (
          <div className=" h-4/5 w-5/5 mx-2 mt-5">
           <FaCalendarDay className=' text-green-700 h-7 w-7'/>
           <p className='font-sans font-bold text-black'> Monday  <span className='font-body text-gray-500'> To </span> Saturday </p>
           
           
          </div>
          
          ): (
            <>
            
           </>
          ) }

        {collaborator.scheduleDays.mondayToFriday ? (
          <div className=" h-4/5 w-5/5 mx-2 mt-5">
           <FaCalendarDay className=' text-green-700 h-7 w-7'/>
           <p className='text-slate-700/70'> Monday to Friday </p>
           
           
          </div>
          
          ): (
            <>
            
           </>
          ) }

        {collaborator.scheduleDays.sunday ? (
          <div className=" h-4/5 w-5/5 mx-2 mt-5 ">
           <FaInfo className=' text-green-700 h-7 w-7'/>
           <p className='font-body'> Work on <span className='font-sans font-bold text-red-700'>Sunday</span>! </p>
           
           
          </div>
          
          ): (
            <>
            
           </>
          ) }
      <div className="h-4/5 w-5/5 mx-2 mt-5 justify-center ">
           <FaClock className=' text-green-700 h-7 w-7 '/>
           <div className=' grid grid-flow-row auto-cols-max'>

            <di>
              <p className='font-body'> <span className='text-justify text-slate-700/70 '> Open: </span> 
              {collaborator.scheduleDays.hoursMToSInit} AM 
              </p>
            </di>
            <div>
              <p className='font-body'>
              <span className='text-justify text-slate-700/70 '> Close: </span> 
              {collaborator.scheduleDays.hoursMToSExit} PM 
              </p>
            </div>
            </div>

          
           
      </div>
        
</div>


  {/* CALL TO DETAILS COLLABORATORS */}                   
    <div className=" pt-2 mx-4 grid grid-flow-row ">
       <Link to={`/collaborator/${collaborator._id}`}
           className=" text-center px-4 py-2 my-2 text-sm  text-custom-red font-semibold rounded-full 
                border border-purple-200  hover:text-white  hover:bg-red-800  hover:border-transparent 
                focus:outline-none focus:ring-2  focus:ring-custom-red focus:ring-offset-2" >
                 
                 Look More Details
        </Link>
    </div>


  {/* CALL TO ACTIONS COLLABORATORS */}          
    <div className="pb-4 mx-4 grid grid-flow-row auto-rows-max">

     <div className=" justify-around mx-4 grid grid-flow-col auto-cols-max">
        <div className=" h-4/5 w-5/5 mx-2 mt-5">

      {/*  **** FORM CAR ACTIONS ****  */}      
        <>
    <div className=" inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModalForm}
          title='FREE QUOTE' className= " px-4 py-1 text-sm text-pink-600 font-semibold rounded-md border border-pink-200 hover:text-white hover:bg-pink-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-pink-600 focus:ring-offset-2"
        >
          <FaFileInvoice className='h-8 w-8  ' />
        </button>
      </div>

      <Transition appear show={isOpenForm} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalForm}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Free Quote to:  <span className='text-green-600'> {collaborator && collaborator.name}</span>
                  </Dialog.Title>
                  <div className="mt-2 max-w-md ">
      <form 
      className='justify-around'
      onSubmit={handleSubmit} >      
      <div className="grid lg:grid-cols-9 xl:grid-cols-9 sm:grid-cols-3 grid-cols-9  gap-6">
              
              <div className="col-span-3 sm:col-span-3">
                <label for="first-name" className="block text-sm font-medium text-gray-800">Name </label>
                <input 
                type="text" 
                //placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="first-name" 
                id="first-name" 
                autocomplete="given-name" 
                className=" border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>

              <div className="col-span-3 sm:col-span-4">
                <label for="email-address" className="block text-sm font-medium text-gray-700">Email address</label>
                <input 
                type="text" 
               // placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email-address" 
                id="email-address" 
                autocomplete="email" 
                className="border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>
              
              <div className="col-span-3 sm:col-span-4">
                <label for="email-address" className="block text-sm font-medium text-gray-700">Cellphone</label>
                <input 
                type="number" 
               // placeholder="Cellphone"
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
                name="cellphone" 
                id="cellphone" 
                autocomplete="Telphone" 
                className=" border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>

              <div className="col-span-6">
                <label for="street-address" className="block text-sm font-medium text-gray-700">Street address</label>
                <input 
                type="text" 
                //placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name="street-address" 
                id="street-address" 
                autocomplete="address" 
                className="border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>
              <div></div><div></div>
              
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
             
        </div>
       
        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
        <div className=" pt-0 pb-8 mt-0 mx-2" >
                  <label className="flex-auto">
                  <span  className="sr-only">Choose profile photo </span>
                  <input className="
                   pl-10 block text-sm  text-slate-500 file:border-solid
                  file:mr-4 file:py-2 file:px-2 file:rounded-md file:border-blue-600
                  file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-600 file:hover:text-black "
                  type="file"
                  //id='customFile'
                  //accept='images/*'
                  multiple
                  //name='reviewsImages'
                  onChange={handleImageChange}

                  />
                    
                 </label>

                  </div>
                  </div>
            </div>
            <br></br>
            <textarea
        className=" w-full mx-6 border-red-800 my-4 popover-body border-2 border-solid text-md"
        placeholder="Description"
        id='description'
        name='description'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
       // style={styles.textarea}
      >
        </textarea>
        <br></br>
        <div className="">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white hover:text-blue-800 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                                    'Sending...'
                                  ) : formSubmitted ? (
                                    <button
                                      className="hover:bg-green-600 "
                                      onClick={closeModalForm}
                                    >
                                     Successfully!
                                    </button>
                                  ) : (
                                    <button
                                      className=""
                                      type="submit"
                                    >
                                      Submit
                                    </button>
                                  )}
                  </button>
                  </div>
    </form>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:text-red-700 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModalForm}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    
        </>
    </div>

    <div className="hidden h-4/5 w-5/5 mx-2 mt-5">
    <button 
    title='CHAT'
    className=
      "  px-4 py-1 text-sm text-red-700 font-semibold rounded-md border border-purple-200 hover:text-white hover:bg-red-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
      >
      <FaKeyboard className='h-10 w-10'/>
    </button>
    </div>

    <> 
    <div className=" h-6 w-5/5 mx-2 mt-7  inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          title='FREE CALL' className= " px-4 py-1 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          <FaPhoneAlt className='h-8 w-8  ' />
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Need Information
                  </Dialog.Title>
                  <div className="mt-2 max-w-md ">
      <form 
      className='justify-around'
      onSubmit={handleCallVonage} >      
      <div className="grid lg:grid-cols-9 xl:grid-cols-9 sm:grid-cols-3 grid-cols-9  gap-6">
              
              <div className="col-span-3 sm:col-span-3">
                <label for="first-name" className="block text-sm font-medium text-gray-700">Name </label>
                <input 
                type="text" 
                //placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="first-name" 
                id="first-name" 
                autocomplete="given-name" 
                className=" border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>

              <div className="col-span-3 sm:col-span-4">
                <label for="email-address" className="block text-sm font-medium text-gray-700">Your phone</label>
                <input 
                type="phone" 
               // placeholder="Email"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                name="phone" 
                id="phone" 
                autocomplete="phone" 
                className="border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>
              
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
             
        </div>
       
       
            </div>
            <br></br>
            {errorMessage && <div className="error">{errorMessage}</div>}
       <button className='inline-flex justify-center rounded-md border border-transparent bg-green-700 px-4 py-2 text-sm font-medium text-white hover:bg-green-100 hover:text-green-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 m-5' type="button" onClick={handleCallVonage}>Star Call</button>
       <button className='inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white hover:bg-red-100 hover:text-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2' type="button" onClick={handleEndCallV}>End Call</button>
    </form>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    
    </> 

   
    </div>
    </div>    
        </div>

        </>    )

    
}

export default CardCollaborators