import axios from "axios";
import Cookies from "js-cookie";




const gtmApi = axios.create({
 
    baseURL: "https://glasstome.us",
    headers: {
      "Content-Type": "application/json",
    },
  });


export default gtmApi