import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAppointment, getAppointments, updateAppointment } from '../../actions/appointmentActions';
import { handleFileInputChange } from '../Utils/FormUtils';
import { Link, useParams } from 'react-router-dom';
import { clearErrors, createAppointment, getCollaboratorDetails } from '../../actions/collaboratorActions';
import { NEW_APPOINTMENT_RESET } from '../../constants/collaboratorConstants';

const localizer = momentLocalizer(moment);

const AppointmentCalendar = () => {

  const { id } = useParams();
  const dispatch = useDispatch();


  const [appointments, setAppointments] = useState([]);
  const collaboratorDetails = useSelector((state) => state.collaboratorDetails);
  const { loadingC, collaborator, error} = collaboratorDetails;
  const { user } = useSelector(state => state.auth);
 
  
 
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [noteGuide, setNoteGuide] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [imagesServices, setImagesServices] = useState([]);
  const [collaboratorId, setCollaboratorId] = useState();
  const [userId, setUserId] = useState('');

   const handleImageAppointment = (e) => {
    setImagesServices(e.target.files)
  };

  const handleSubmitAppointment = (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    const imagesServicesArray = Array.from(imagesServices);
    imagesServicesArray.forEach((imagesServices) => {
      formData.append('imagesServices', imagesServices);
    });
    formData.append('description', description)
    formData.append('name', name)
    formData.append('address', address)
    formData.append('noteGuide', noteGuide)
    formData.append('startTime', startTime)
    formData.append('endTime', endTime)
    formData.append('collaboratorId', id)
    formData.append('userId', user._id)
    dispatch(createAppointment(formData));
  }  
    
    useEffect(() => {
      dispatch(getCollaboratorDetails(id));
  
      if (error) {
        alert.error(error);
        dispatch(clearErrors());
      }
  
      // if (appointmentError) {
      //   alert.error(appointmentError);
      //   dispatch(clearErrors());
      // }
  
      // if (success) {
      //   alert.success('Appointment posted successfully');
      //   dispatch({ type: NEW_APPOINTMENT_RESET });
      // }
    }, [dispatch, id, alert, error]);


  //const [appointments, setAppointments] = useState([]);
  const [formData, setFormData] = useState({
    collaboratorId: collaborator.id,
    user,
    description: '',
    address: '',
    name: '',
    startTime: '',
    endTime: '',
    status: '',
    noteGuide: '',
  });

  useEffect(() => {
    getAppointments().then((data) => {
      setAppointments(data);
    });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const appointmentData = {
      name: formData.name,
      address: formData.address,
      description: formData.description,
      startTime: formData.startTime,
      endTime: formData.endTime
    };
  
    // Validar campos requeridos
    const missingFields = Object.entries(appointmentData)
      .filter(([key, value]) => !value)
      .map(([key, value]) => key);
  
    if (missingFields.length > 0) {
      alert(`Please fill in the following fields: ${missingFields.join(', ')}`);
      return;
    }
  
    const form = new FormData();
    if (formData.imagesServices && formData.imagesServices.length > 0) {
      for (let i = 0; i < formData.imagesServices.length; i++) {
        form.append("imagesServices", formData.imagesServices[i]);
      }
    }
  
    form.append("description", formData.description);
    form.append("address", formData.address);
    form.append("name", formData.name);
    form.append("startTime", formData.startTime);
    form.append("endTime", formData.endTime);
    form.append("status", formData.status);
    form.append("noteGuide", formData.noteGuide);
  
    try {
      const response = await axios.post("/api/v1/appointment", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      // Add the newly created appointment to the appointments state
      setAppointments((prevState) => [...prevState, response.data]);
      // Clear the form data
      setFormData({
        //  collaboratorId: collaborator.id,
        description: '',
        address: '',
        name: '',
        startTime: '',
        endTime: '',
        status: '',
        noteGuide: '',
        user
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id, newData) => {
    updateAppointment(id, newData).then((data) => {
      setAppointments((prevState) =>
        prevState.map((appointment) => (appointment._id === data.appointment._id ? data.appointment : appointment))
      );
    });
  };

  const handleDelete = (id) => {
    deleteAppointment(id).then((data) => {
      setAppointments((prevState) => prevState.filter((appointment) => appointment._id !== data.appointment._id));
    });
  };

  const fetchAppointments = async () => {
    try {
      const response = await axios.get('/api/v1/appointment');
      setAppointments(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    
    const timeoutId = setTimeout(() => {
      axios.get(`/appointments/${collaborator._id}`)
        .then(res => {
          setAppointments(res.data);
          fetchAppointments();
        })
        .catch(err => {
          console.log(err);
        });
    }, 7000);
  
    return () => clearTimeout(timeoutId);
  }, [collaborator]);

  
  

  return ( 
     <> 
   
   {user ? (
  <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
    <div className="grid grid-flow-col auto-cols-max">
      <img
        className="h-16 w-16 object-cover rounded-full hover:scale-150"
        src={user.avatar && user.avatar.url}
        alt={user && user.name}
      />
      <div className="mx-5 grid grid-flow-row auto-rows-max">
        <p className="text-lg text-black font-semibold">{user && user.name}</p>
      </div>
    </div>
    <form onSubmit={handleSubmitAppointment}>
      {/* <textarea
        className="hidden mx-6 border-red-800 my-4 popover-body border-2 border-solid text-md"
        placeholder="Collaborator ID"
        id="collaboratorId"
        type="hidden"
        name="collaboratorId"
        value={collaboratorId}
        onChange={(e) => setCollaboratorId(e.target.value)}
        // style={styles.textarea}
      ></textarea> */}
        <input type="hidden" name="userId" value={userId} />
      <label className="block mb-2">
        Name:
        <input
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </label>
      <label className="block mb-2">
        Description:
        <textarea
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          
        </textarea>
      </label>
      <label className="block mb-2">
        Address:
        <input
          name="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        ></input>
      </label>
      <label className="block mb-2">
        Start Time:
        <input
          type="datetime-local"
          name="startTime"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </label>
      <label className="block mb-2">
        End Time:
        <input
  type="datetime-local"
  name="endTime"
  value={endTime}
  onChange={(e) => setEndTime(e.target.value)}
  className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
/>
</label>
<label className="block mb-2">
Note:
<textarea
name="noteGuide"
value={noteGuide}
onChange={(e) => setNoteGuide(e.target.value)}
className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
></textarea>
</label>
<label className="block mb-2">
Images:
<input
       type="file"
       name="imagesServices"
       multiple
       onChange={handleImageAppointment}
       className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
     />
</label>
<button
     type="submit"
     className="inline-flex justify-center rounded-md border border-transparent bg-blue-200 ml-8 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
   >
Submit
</button>
</form>
</div>
  ) : 
  
  <></>
    
    
  }
    

     <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 ">
 
</div>

    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 ">
    <div style={{ height: 500 }}>
      <Calendar
        localizer={localizer}
        events={appointments}
        startAccessor="startTime"
        endAccessor="endTime"
        style={{ margin: '0 auto' }}
      />
    </div>
    </div>
    </>
  );
};

export default AppointmentCalendar;
