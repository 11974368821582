
import './index.css';


import Navbar from './Components/Navbar'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; 
import Home from "./Components/pages/Home";
import Collaborators from "./Components/pages/Collaborators";

import AboutUs from "./Components/pages/AboutUs";

import FormRegisterTail from "./Components/Utils/FormRegister";


import FormLoginTail from './Components/Utils/FormLogin';

import CollaboratorsDetails from './Collaborators/Pages/CollaboratorsDetails';
import Footer from './Components/Footer';

import UpdateProfile from './Client/Pages/UpdateProfile';
import Profile from './Client/Pages/Profile';
import LoginCollaborator from './Collaborators/Pages/loginCollaborator';
import PrivacyPolicy from './Components/pages/PrivacyPolicy';
import TermsAndConditions from './Components/pages/Terms&Conditions';
import FrequentQuestions from './Components/pages/FrequentQuestions';
import CookieBanner from './Components/CookieBanner';
import ProfileCollaborator from './Collaborators/Pages/ProfileCollaborator';

import FormRegisterCollaborator from './Collaborators/Pages/FormRegisterCollaborator';
import UpdateProfileCollaborator from './Collaborators/Pages/UpdateProfileCollaborator';


function App() {
 
  return (
   
    <>     

               <Navbar />       
              <CookieBanner />
        <Routes>             
       
                        <Route exact path='/*' element={<Navigate to='/' />} />
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/home' element={<Home />} />
                       
                        <Route exact path='/collaborators/*' element={<Collaborators />} />

                       
                        <Route path="/search/:keyword" component={<Collaborators />} />
                         <Route path="/collaborator/:id" element={<CollaboratorsDetails />} /> 

                        
                         {/* <Route PrivateRoute path='/collaborator/me' element={<Profi /> } /> */}

                        <Route exact path='/about-us' element={<AboutUs />} />

                        {/* USER PROFILE */}
                        <Route exact path='/login' element={<FormLoginTail />} />
                        <Route exact path='/register' element={<FormRegisterTail />} />  
                        <Route PrivateRoute path='/me' element={<Profile /> } />
                        <Route PrivateRoute path='/me/update' element={<UpdateProfile /> } />

                         {/* COLLABORATOR PROFILE */}
                         <Route path='/login-collaborator' element={<LoginCollaborator/>} />
                         <Route path='/register-collaborator' element={<FormRegisterCollaborator/>} />
                         <Route PrivateRoute path='/collaborator-me' element={<ProfileCollaborator /> } />
                        <Route PrivateRoute path='/collaborator-me/update' element={<UpdateProfileCollaborator /> } />

                      <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />                  
                      <Route exact path='/terms-&-conditions' element={<TermsAndConditions />} />                  
                      <Route exact path='/frequent-questions' element={<FrequentQuestions />} />                  
       </Routes>

    <Footer />
    </>
  );
}

export default App;