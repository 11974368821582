import { GlobeAltIcon, PhoneOutgoingIcon, StatusOnlineIcon } from '@heroicons/react/outline';
import { useEffect, useState, Fragment} from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { FaAward, FaCalendarDay, FaCarSide, FaCcMastercard, FaCertificate, FaClock, 
         FaFileContract, FaFileInvoice, FaLaptopCode, FaMoneyBill, FaMoneyCheckAlt, FaPhoneAlt, FaRegCalendarCheck, FaRegWindowRestore, 
         FaShippingFast, FaStar, FaTools, FaTruck, FaWarehouse 
        } from 'react-icons/fa';


import { useSelector, useDispatch } from "react-redux";
import {   Link, useParams } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { clearErrors, createAppointment, getCollaboratorAppointments, getCollaboratorDetails, newReview } from '../../actions/collaboratorActions';
import MetaData from '../../Components/layout/MetaData';
import { NEW_REVIEW_RESET } from '../../constants/collaboratorConstants';
import ListReviews from '../review/ListReviews';
import StarRatings from 'react-star-ratings';
import Loading from '../../utils/Loading';
import ReviewsCollaborators from '../Components/reviewsCollaborators';
import GalleryCollaborators from '../Components/GalleryCollaborators';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import FormCar from '../../Components/FormCar';
import axios from 'axios';
import CreateAppointment from '../Components/CalendarCollaborator';
import Appointments from '../Components/Appointments';
import AppointmentCalendar from '../Components/AppointmentsCalendar';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import AppointmentModal from '../Hooks/modalAppointment';
import AppointmentsSlider from '../Hooks/sliderAppointment';
import gtmApi from '../../api/gtmApi';


const localizer = momentLocalizer(moment);

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
  red : "#b91c1c",
  
};

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

const CollaboratorsDetails = () => {

  // *********  FORM CAR QUOTE FREE *********
  let [isOpenForm, setIsOpenForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  function closeModalForm() {
    setIsOpenForm(false)
  }

  function openModalForm() {
    setIsOpenForm(true)
  }

const [formImages, setFormImages] = useState([]);
const [cellphone, setCellphone] = useState('');
const [email, setEmail] = useState('');
const [address, setAddress] = useState('');
const [description, setDescription] = useState('');

const handleImageChangeForm = (event) => {
  setFormImages(event.target.files);
 
};

const handleSubmitFomr = async (e) => {
  e.preventDefault();

 
  const formData = new FormData();
  const formImagesArray = Array.from(formImages);
  formImagesArray.forEach((formImage) => {
    formData.append('FormImages', formImage);
  });

  // Verificar que cada campo tenga un valor
if (!name || !email || !cellphone || !address || !description) {
  alert('Please complete each of the fields.');
  return;
}

  // Verificar que al menos una imagen se cargue
  if (formImagesArray.length === 0) {
    alert('Please upload at least one image');
    
    return;
  }
  setIsLoading(true)
  setFormSubmitted(false)

  formData.append('name', name);
  formData.append('email', email);
  formData.append('cellphone', cellphone);
  formData.append('address', address);
  formData.append('description', description);

  if (collaborator && collaborator.email) {
    formData.append('emailCollaborator', collaborator.email);
  }

  try { 
    const res = await axios.post(`/api/v1/collaborator/${collaborator._id}/submit-form-car`, formData);
    console.log(res.data);
    setIsLoading(false)
    setFormSubmitted(true)
    //alert('Form sent successfully!');
  } catch (err) {
    console.error(err);
  }
};


  const [isOpen, setIsOpen] = useState(false)
  const [isOpenReview, setIsOpenReview] = useState(false)
  
 
    const [activeImage, setActiveImage] = useState(null);
  
    const handleImageClick = (index) => {
      setActiveImage(index);
    };

  const [collaboratorId, setCollaboratorId] = useState();


  const { id } = useParams();
  const dispatch = useDispatch();
  const collaboratorDetails = useSelector((state) => state.collaboratorDetails);
  const { loadingC, collaborator, error} = collaboratorDetails;
  
  
  //const { loadingC, error, collaborator } = useSelector(state => state.collaboratorDetails);
  

  const { collaboratorAppointments } = useSelector((state) => state.appointmentReducer);

  const { user } = useSelector(state => state.auth);
  const { error: reviewError, success } = useSelector(state => state.newReview);


  const [rating , setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [reviewImages, setReviewImages] = useState([])

 
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  
  const stars = Array(5).fill(0)


  //****************CALLSSS!!!!!!!!!!!!!
  const [isOpenCall, setIsOpenCall] = useState(false)

  function closeModalCall() {
    setIsOpenCall(false)
  }

  function openModalCall() {
    setIsOpenCall(true)
  }

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [callSid, setCallSid] = useState(null);
  const [duration, setDuration] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // ******* LLAMADA CON VONAGE ******
const handleCallVonage = async (e) => {
  e.preventDefault();

  const data = { name, phone };
  try {
    const res = await gtmApi.post(`/api/v1/collaborator/${collaborator._id}/callv`, data);
    setCallSid(res.data.callSid);
    setMessage(`Call started successfully.`);
  } catch (error) {
    console.error(error);
    setMessage('Failed to start the call. Please try again.');
  }
};

const handleEndCallV = async () => {
  try {
    await gtmApi.delete(`/api/v1/close-call/${callSid}`);
    setDuration('Call ended');
  } catch (error) {
    console.error(error);
    setMessage('Failed to end the call. Please try again.');
  }
};


  const handleSubmitCall = async (e) => {
    e.preventDefault();
    const data = { name, phone };
    try {
      const res = await axios.post('/api/v1/calls', data);
      setMessage(`Call started successfully.`);
      // setMessage(`Call started successfully. El ID de la llamada es ${res.data}.`);
    } catch (error) {
      console.error(error);
      setMessage('Failed to start the call. Please try again.');
    }
  };
  
  const handleStartCall = async () => {
    const data = { name, phone};
    if (!name || !phone) {
      setErrorMessage('Please, complete all fields.'); }
    try {
      const collaboratorId = id
      const res = await axios.post(`/api/v1/collaborator/${collaborator._id}/call`, data);
      setCallSid(res.data);
      setMessage(`Call started successfully.`);
      // setMessage(`Call started successfully. El ID de la llamada es ${res.data}.`);
    } catch (error) {
      console.error(error);
      setMessage('Failed to start the call. Please try again.');
    }
  };
  
  const handleEndCall = async () => {
    try {
      await axios.delete(`/${callSid}`);
      setDuration('Call ended');
    } catch (error) {
      console.error(error);
      setMessage('Failed to start the call. Please try again.');
    }
  };
  // **************  APPOINTMENTS  *********************
  const [isOpenAppointment, setIsOpenAppointment] = useState(false)
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  function closeModalAppointment() {
    setIsOpenAppointment(false)
  }

  function openModalAppointment() {
    setIsOpenAppointment(true)
  }
  const [appointments, setAppointments] = useState([]);
  const [noteGuide, setNoteGuide] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [imagesServices, setImagesServices] = useState([]);
  const [userId, setUserId] = useState('');

  const handleImageAppointment = (e) => {
    setImagesServices(e.target.files)
  };

  const handleSubmitAppointment = (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    const imagesServicesArray = Array.from(imagesServices);
    imagesServicesArray.forEach((imagesServices) => {
      formData.append('imagesServices', imagesServices);
    });
    formData.append('description', description)
    formData.append('name', name)
    formData.append('address', address)
    formData.append('noteGuide', noteGuide)
    formData.append('startTime', startTime)
    formData.append('endTime', endTime)
    formData.append('collaboratorId', id)
    formData.append('userId', user._id)
    dispatch(createAppointment(formData));
  }  

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(getCollaboratorAppointments());
    }, 8000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  useEffect(() => {
    if (collaboratorAppointments) {
      setAppointments(collaboratorAppointments);
    }
  }, [collaboratorAppointments]);

   // Map the appointments to the events array
  const events = collaboratorAppointments.map((appointments) => ({
    start: moment(appointments.startTime).toDate(),
    end: moment(appointments.endTime).toDate(),
    title: appointments.name,
    allDay: false,
    collaboratorId: appointments.collaboratorId,
    appointmentId: appointments._id,
  }));

  //CALENDAR APPOINTMENT
  
  
  const handleSelectEvent = (event) => {
    setSelectedAppointment(event);
  };
  const handleCloseModal = () => {
    setSelectedAppointment(null);
  };
  
  //RATING
  const handleClick = value => {
    setRating(value);
    setCurrentValue(value)
  }

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue) 
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }

const handleImageChange = (e) => {
  setReviewImages(e.target.files)
};

const handleSubmit = (e) => {
  e.preventDefault();

  const formData = new FormData();
  const reviewImagesArray = Array.from(reviewImages);
  reviewImagesArray.forEach((reviewImage) => {
    formData.append('reviewImages', reviewImage);
  });
  formData.append('rating', rating)
  formData.append('comment', comment)
  formData.append('collaboratorId', id)
  dispatch(newReview(formData));
}  
  
  useEffect(() => {
    dispatch(getCollaboratorDetails(id));

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (reviewError) {
      alert.error(reviewError);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success('Reivew posted successfully');
      dispatch({ type: NEW_REVIEW_RESET });
    }
  }, [dispatch, id, alert, error, reviewError, success]);


  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };



  return (
    <>
    {/*HERO SECTION COLLABORATORS (I THINK I CAN DO BETTER) */}


    <MetaData title={collaborator.name} />

    
    
     
        <div>
        {loadingC ? <Loading /> 
        :( 
           <div
           
           className=" relative overflow-hidden bg-white" key={collaborator && collaborator.id}>
           <div className="mx-auto max-w-7xl">
             <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
               <svg
                 className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-white lg:block"
                 fill="currentColor"
                 viewBox="0 0 100 100"
                 preserveAspectRatio="none"
                 aria-hidden="true"
               >
                 <polygon points="50,0 100,0 50,100 0,100" />
               </svg>

               <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className=" text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl md:text-6xl">
                <span className="block  xl:inline md:inline-block lg:inline-block">{collaborator.name}     </span> <br></br>
                <span className="block text-4xl font-sans text-blue-600 xl:inline md:inline-block lg:inline-block">{collaborator.slogan}</span>
              </h1>
              <p className="mt-3 text-sans text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                {collaborator.description}
              </p>
             

              <div className=" border-b-gray-600 border-b-2 pb-2  justify-around mx-4 grid grid-flow-col auto-cols-max">
              <div className=" justify-start  grid grid-flow-col auto-cols-max">
       
              <p className='pl-2 text-sm text-yellow-500 hover:text-red-700 mt-auto'>{ `${(collaborator.ratings / 5) * 100}%` } </p>
              <FaStar className=' hover:text-red-700 text-yellow-500 h-5 w-5' />      
              </div>
                
               {/* <p className='text-sm text-yellow-500 hover:text-red-700 mt-auto'>{(collaborators.ratings / 5) * 100}%</p> */}
               <span className="text-sm text-gray-500 ml-2">({collaborator.numOfReviews} Reviews)</span>
              </div>

      {/* REVIEWS TEST */}
            {user ?    <>      

      {/* MODAL BOTON */}

      <div className="py-4 relative inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={ openModal }
          
          className=
          "flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 px-8 py-3 text-base font-medium text-white hover:bg-red-700 md:py-4 md:px-10 md:text-lg"
        >
          Set Riview
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className=" fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="pl-9 text-lg font-medium leading-6 text-gray-900"
                  >
                   <div className=" group mb-2  p-6 bg-gray-50">
        <div className="grid  grid-flow-col auto-cols-max" >
        <img className="h-16 w-16 object-cover rounded-full hover:scale-150 " 
         src={ user.avatar && user.avatar.url}  
         alt={user && user.name } />
      <div className=" mx-5 grid grid-flow-row auto-rows-max">
      <p className="text-lg text-black font-semibold">
        {user && user.name}
      </p>
      <p className="text-slate-500 font-medium group-hover:text-red-600 ">
        Need here info Vehicle
      </p>
      </div>
      </div>
    </div>
                  </Dialog.Title>
                  {/* SET USER REVIEW FRONTEND */}
      
         <form onSubmit={handleSubmit} >
                  
                  <textarea
        className="hidden mx-6 border-red-800 my-4 popover-body border-2 border-solid text-md"
        placeholder="Collaborator ID"
        id='collaboratorId'
        type = "hidden"
        name='collaboratorId'
        value={collaboratorId}
      // onChange={(e) => setCollaboratorId(e.target.value)}
       // style={styles.textarea}
      >
  
        </textarea>
        
        
        <div className="   py-8 px-0 max-w-lg mx-auto bg-white rounded-xl shadow-lg  sm:py-2 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
        <div className=" w-full  grid grid-flow-row auto-rows-max ">        
        
        <div className="group mt-2 pl-6 flex row-auto hover:ring-sky-500">
        {stars.map((_, index) => {
          return (
            <div className="group  hover:ring-sky-500">
            <FaStar
              key={index}
              size={24}
              value={rating}
              onClick={() => handleClick(index + 1)}
              onMouseOver={() => handleMouseOver(index + 1)}
              onMouseLeave={handleMouseLeave}
              color={(hoverValue || rating) > index ? colors.orange : colors.grey}
            
            />
            </div>
          )
          
        })}
      </div>
       
                  <textarea
        className="mx-6 border-red-800 my-4 popover-body border-2 border-solid text-md"
        placeholder="What's your experience?"
        id='comment'
        name='comment'
        value={comment}
        onChange={(e) => setComment(e.target.value)}
       // style={styles.textarea}
      >
  
        </textarea>
        </div>
        </div>
        <div className="   py-8 px-0 max-w-lg mx-auto bg-white rounded-xl shadow-lg  sm:py-2 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
           <div className=" w-full  grid grid-flow-row auto-rows-max ">
       

  </div>
  </div>
 
                  <div className=" pt-0 pb-8 mt-0 mx-2" >
                  <label className="flex-auto">
                  <span  className="sr-only">Choose profile photo </span>
                  <input className="
                   pl-10 block text-sm  text-slate-500 file:border-solid
                  file:mr-4 file:py-2 file:px-2 file:rounded-md file:border-blue-600
                  file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-600 file:hover:text-black "
                  type="file"
                  //id='customFile'
                  //accept='images/*'
                  multiple
                  //name='reviewsImages'
                  onChange={handleImageChange}

                  />
                    
                 </label>

                  </div>
                  

                 

                  <div className="mt-4 grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 justify-between ">
      
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border 
                                 border-transparent bg-blue-200 ml-8 px-4 py-2 text-sm font-medium 
                                 text-blue-900 hover:bg-blue-400 hover:text-white focus:outline-none focus-visible:ring-2 
                                 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                 onClick={closeModal}
                    >
                      Submit
                    </button>
                    <button

                      type="button"
                      className="inline-flex justify-center rounded-md border 
                                 border-transparent bg-red-200 ml-8 px-4 py-2 text-sm font-medium 
                                 text-red-900 hover:bg-red-400 hover:text-red-50 focus:outline-none focus-visible:ring-2 
                                 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                    Cancel
                    </button>
                  </div>
              </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </> :
    <div className='py-4'>
      <Link
          type="button"
          to='/login'          
          className=
          "flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 px-8 py-3 text-base font-medium text-white hover:bg-red-700 md:py-4 md:px-10 md:text-lg"
        >
          Login to set review
        </Link>
    </div>
    
    }


                  
             
              <div className="mt-5 sm:flex lg:justify-around sm:justify-around md:justify-around justify-around">
                <div className="">
                   {/*  **** FORM CAR ACTIONS ****  */}      
        <>
    <div className=" inset-0 flex items-center justify-center sm:justify-star">
        <button
          type="button"
          onClick={openModalForm}
          title='FREE QUOTE' className= " px-4 py-1 text-sm text-pink-600 font-semibold rounded-md border border-pink-200 hover:text-white hover:bg-pink-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-pink-600 focus:ring-offset-2"
        >
          <FaFileInvoice className='h-8 w-8  ' />
        </button>
      </div>

      <Transition appear show={isOpenForm} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalForm}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Free Quote to:  <span className='text-green-800'> {collaborator && collaborator.name}</span>
                  </Dialog.Title>
                  <div className="mt-2 max-w-md ">
      <form 
      className='justify-around'
      onSubmit={handleSubmitFomr} >      
      <div className="grid lg:grid-cols-9 xl:grid-cols-9 sm:grid-cols-3 grid-cols-9  gap-6">
              
              <div className="col-span-3 sm:col-span-3">
                <label for="first-name" className="block text-sm font-medium text-gray-800">Name </label>
                <input 
                type="text" 
                //placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="first-name" 
                id="first-name" 
                autocomplete="given-name" 
                className=" border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>

              <div className="col-span-3 sm:col-span-4">
                <label for="email-address" className="block text-sm font-medium text-gray-700">Email address</label>
                <input 
                type="text" 
               // placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email-address" 
                id="email-address" 
                autocomplete="email" 
                className="border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>
              
              <div className="col-span-3 sm:col-span-4">
                <label for="email-address" className="block text-sm font-medium text-gray-700">Cellphone</label>
                <input 
                type="number" 
               // placeholder="Cellphone"
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
                name="cellphone" 
                id="cellphone" 
                autocomplete="Telphone" 
                className=" border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>

              <div className="col-span-6">
                <label for="street-address" className="block text-sm font-medium text-gray-700">Street address</label>
                <input 
                type="text" 
                //placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name="street-address" 
                id="street-address" 
                autocomplete="address" 
                className="border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>
              <div></div><div></div>
              
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
             
        </div>
       
        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
        <div className=" pt-0 pb-8 mt-0 mx-2" >
                  <label className="flex-auto">
                  <span  className="sr-only">Choose profile photo </span>
                  <input className="
                   pl-10 block text-sm  text-slate-500 file:border-solid
                  file:mr-4 file:py-2 file:px-2 file:rounded-md file:border-blue-600
                  file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-600 file:hover:text-black "
                  type="file"
                  //id='customFile'
                  //accept='images/*'
                  multiple
                  //name='reviewsImages'
                  onChange={handleImageChangeForm}

                  />
                    
                 </label>

                  </div>
                  </div>
            </div>
            <br></br>
            <textarea
        className=" w-full mx-6 border-red-800 my-4 popover-body border-2 border-solid text-md"
        placeholder="Description"
        id='description'
        name='description'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
       // style={styles.textarea}
      >
        </textarea>
        <br></br>
        <div className="">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white hover:text-blue-800 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                                    'Sending...'
                                  ) : formSubmitted ? (
                                    <button
                                      className="hover:bg-green-600 "
                                      onClick={closeModalForm}
                                    >
                                     Successfully!
                                    </button>
                                  ) : (
                                    <button
                                      className=""
                                      type="submit"
                                    >
                                      Submit
                                    </button>
                                  )}
                  </button>
                  </div>
    </form>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:text-red-700 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModalForm}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    
        </>
                </div>
                <div className="hidden  mt-3 lg:mx-3 sm:mx-3 sm:mt-0   sm:ml-3">
                  <Link
                    to="#"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200 md:py-4 md:px-10 md:text-lg"
                  >
                    Chat
                  </Link>
                </div>
                {/* **********************call********************* */}
                <> 
    <div className="  mt-1 h-6 w-5/5 mx-2   inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModalCall}
          title='FREE CALL' className= " px-4 py-1 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          <FaPhoneAlt className='h-8 w-8  ' />
          
        </button>
        
      </div>

      <Transition appear show={isOpenCall} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalCall}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Need Information
                  </Dialog.Title>
                  <div className="mt-2 max-w-md ">
      <form 
      className='justify-around'
      onSubmit={handleCallVonage} >      
      <div className="grid lg:grid-cols-9 xl:grid-cols-9 sm:grid-cols-3 grid-cols-9  gap-6">
              
              <div className="col-span-3 sm:col-span-3">
                <label for="first-name" className="block text-sm font-medium text-gray-700">Name </label>
                <input 
                type="text" 
                //placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="first-name" 
                id="first-name" 
                autocomplete="given-name" 
                className=" border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>

              <div className="col-span-3 sm:col-span-4">
                <label for="email-address" className="block text-sm font-medium text-gray-700">Your phone</label>
                <input 
                type="phone" 
               // placeholder="Email"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                name="phone" 
                id="phone" 
                autocomplete="phone" 
                className="border-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>
              
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
             
        </div>
       
       
            </div>
            <br></br>
            {errorMessage && <div className="error">{errorMessage}</div>}
       <button className='inline-flex justify-center rounded-md border border-transparent bg-green-700 px-4 py-2 text-sm font-medium text-white hover:bg-green-100 hover:text-green-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 m-5' type="button" onClick={handleCallVonage}>Star Call</button>
       <button className='inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white hover:bg-red-100 hover:text-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2' type="button" onClick={handleEndCallV}>End Call</button>
    </form>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModalCall}
                    >
                      Cancel!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    
    </>
              
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          {collaborator.avatar && collaborator.avatar.map(avatar => [
                 <img
                 className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
                 src={avatar.url}
                
                 alt="Auto-Glass"
               />
          ])}  
        
       
      </div>
    </div>
        )}
        </div>

      {/*REVIEW SECTION COLLABORATOR*/}
      <>
        
        
          <ReviewsCollaborators />  
          

         
     </>
     
     {/*PERFORMANCE SECTION COLLABORATOR*/}

    
     <div className="py-12 bg-gray-100 pt-15">
      <div className="text-left max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className=" text-left">
          {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
          <p className="text-4xl font-body text-left tracking-tight font-extrabold text-black sm:text-5xl md:text-6xl">
             Skills & Performance
          </p>
          <p className=" pl-1 font-sans mt-4 max-w-2xl text-left text-xl text-gray-700 ">
          Our collaborators possess a diverse set of skills and expertise to provide you with the best possible service. 
          From technical knowledge to customer service experience, we have a team of professionals who are committed to 
          delivering top-notch solutions. If you have any questions or concerns, please feel free to contact us. 
          We're here to ensure that you have a positive experience with us.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-8 sm:space-y-0 md:space-y-0 lg:space-y-0 xl:space-y-0 
                        grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4  md:grid-cols-3 sm:grid-cols-2 
                        md:gap-x-5 md:gap-y-10 sm:gap-x-5 sm:gap-y-10 lg:gap-x-5 lg:gap-y-10 xl:gap-x-5 xl:gap-y-10">
           
           
              {collaborator.performanceCollaborator && collaborator.performanceCollaborator.map(performanceCollaborator => [
              
              
              performanceCollaborator.oEMGlass ? (
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                  <FaCarSide className=' h-7 w-7'/>
                  </div>
                  <p className="pt-3 ml-16 font-body text-lg leading-6 font-medium text-gray-900">OEM Glass </p>
                </dt>
              </div> 
              ):(
                <></>
              ),
              performanceCollaborator.warranty ? (
                <div className="relative">
                  <dt className=''>
                    <div className=" absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                    <FaAward  className='h-7 w-7'/>
                    </div>
                    <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Warranty </p>
                  </dt>
                </div> 
                ):(
                  <></>
                ),
                performanceCollaborator.replacement ? (
                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                      <FaTools className='h-7 w-7'/>
                      </div>
                      <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Replacement </p>
                    </dt>
                  </div> 
                  ):(
                    <></>
                  ),  
                  performanceCollaborator.insurance ? (
                    <div className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                        <FaFileContract className='h-7 w-7'/>
                        </div>
                        <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Insurance </p>
                      </dt>
                    </div> 
                    ):(
                      <></>
                    ),
                    performanceCollaborator.repair ? (
                      <div className="relative">
                        <dt>
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                          <FaRegWindowRestore className='h-7 w-7'/>
                          </div>
                          <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Repair </p>
                        </dt>
                      </div> 
                      ):(
                        <></>
                      ),

                  performanceCollaborator.calibration ? (
                    <div className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                        <FaLaptopCode className='h-7 w-7'/>
                        </div>
                        <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Calibration </p>
                      </dt>
                    </div> 
                    ):(
                      <></>
                    ),
                    performanceCollaborator.nextDayService ? (
                      <div className="relative">
                        <dt>
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                          <FaRegCalendarCheck className='h-7 w-7'/>
                          </div>
                          <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Next Day Service </p>
                        </dt>
                      </div> 
                      ):(
                        <></>
                      ),
                      performanceCollaborator.registerAGRSS ? (
                        <div className="relative">
                          <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                            <FaCertificate className='h-7 w-7'/>
                            </div>
                            <p className="pt-3 ml-16 text-lg leading-6 font-body font-medium text-gray-900"> Regidter AGRSS </p>
                          </dt>
                        </div> 
                        ):(
                          <></>
                        ),
                        performanceCollaborator.freeMobileService ? (
                          <div className="relative">
                            <dt>
                              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                              <FaShippingFast className='h-7 w-7'/>
                              </div>
                              <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Free Mobile Service </p>
                            </dt>
                          </div> 
                          ):(
                        <></>
                      ),
                      performanceCollaborator.localServices ? (
                        <div className="relative">
                          <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                            <FaWarehouse className='h-7 w-7'/>
                            </div>
                            <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Local Services </p>
                          </dt>
                        </div> 
                        ):(
                          <></>
                        ),
                        performanceCollaborator.deliveryServices ? (
                          <div className="relative">
                            <dt>
                              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                                  <FaTruck className='h-7 w-7'/>
                                  </div>
                                  <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Delivery Services </p>
                                </dt>
                              </div> 
                              ):(
                                <></>
                              ),  
              ])}
        
       
              
          </dl>
        </div>
      </div>
    </div>

    {/*GALERY SECTION COLLABORATORS */}
     
    <GalleryCollaborators />

      {/* COLLABORATOR PAYMENTS */}
    <div className=" py-6 bg-gray-100 pt-15">
      <div className="text-left max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className=" text-left">
          {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
          <p className="text-4xl text-left font-body tracking-tight font-extrabold text-black sm:text-5xl md:text-6xl">
          Payment method 
          </p>
          <p className=" pl-1 mt-4 font-sans max-w-2xl text-left text-xl text-gray-700 ">
          Payment methods accepted by our collaborators, remember to ask to be sure. This may have changes that have not been reflected yet.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-8 sm:space-y-0 md:space-y-0 lg:space-y-0 xl:space-y-0 
                        grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4  md:grid-cols-3 sm:grid-cols-2 
                        md:gap-x-5 md:gap-y-10 sm:gap-x-5 sm:gap-y-10 lg:gap-x-5 lg:gap-y-10 xl:gap-x-5 xl:gap-y-10">
          
        {collaborator?.paymentMethod?.cash ? (
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-700  text-white">
                  <FaMoneyBill className=' h-7 w-7'/>
                  </div>
                  <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900">Cash </p>
                </dt>
              </div> 
              ):(
                <></>
              ) }
          {collaborator?.paymentMethod?.card ? (
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                  <FaCcMastercard className=' h-7 w-7'/>
                  </div>
                  <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900">Card </p>
                </dt>
              </div> 
              ):(
                <></>
              ) }
      {collaborator?.paymentMethod?.check ? (
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                  <FaMoneyCheckAlt className=' h-7 w-7'/>
                  </div>
                  <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900">Check </p>
                </dt>
              </div> 
              ):(
                <></>
              ) }
           </dl>
           </div>
           </div>
           </div>
           

     
       <> 

    {/*INFORMCATION SECTION COLLABORATOR*/}
    
    <div className="py-12 bg-white pt-15">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
          <p className="text-4xl text-left tracking-tight font-body font-extrabold text-black sm:text-5xl md:text-6xl">
            Schelude information
          </p>
          <p className="mt-4 max-w-2xl font-sans text-left text-xl text-gray-700 ">
          We kindly remind you to always double-check with our collaborators for any exceptions to the schedule, 
          as there may be changes that have not yet been updated. Our top priority is to ensure that the process 
          is transparent and that both parties have full control over it. So, if you have any doubts or concerns, 
          please do not hesitate to ask.
          </p>
        </div>

        <div className="mt-10">
          <dl className="
            space-y-8 sm:space-y-0 md:space-y-0 lg:space-y-0 xl:space-y-0 
            grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3  md:grid-cols-3 sm:grid-cols-2 
            md:gap-x-5 md:gap-y-10 sm:gap-x-5 sm:gap-y-10 lg:gap-x-5 lg:gap-y-10 xl:gap-x-5 xl:gap-y-10
          ">
           
              {/* {collaborator.addressInfo && collaborator.addressInfo.map(addressInfo => [
              <>  <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Schedule information </p>
                </dt>
             
                <dd className="mt-2 ml-16 text-base text-gray-500">
                <big className='text-black text-base'>Address </big>
                {addressInfo.address}, {addressInfo.city}, {addressInfo.postalCode} 
               
                </dd> 
                 <dd className="mt-2 ml-16 text-base text-gray-500"><big className='text-black text-base'>Email </big> {collaborator.email}</dd> 
                 <dd className="mt-2 ml-16 text-base text-gray-500"><big className='text-black text-base'>Owner </big>  {collaborator.owner} </dd> 
                 
              </div>

              <div  className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <PhoneOutgoingIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Contact Information</p>
                </dt>
                 <dd className="mt-2 ml-16 text-base text-gray-500 lining-nums ">
                  <big className='text-black text-base'>Office </big> 
                  {addressInfo.officeNo}
                  </dd> 
                 <dd className="mt-2 ml-16 text-base text-gray-500 lining-nums">
                  <big className='text-black text-base'>Phone </big> 
                  {addressInfo.phoneNo}
                  </dd> 
                 <dd className="mt-2 ml-16 text-base text-gray-500 lining-nums">
                  <big className='text-black text-base'>Cellphone </big> 
                  {addressInfo.cellNo}
                  </dd>  
              </div>

              <div  className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <StatusOnlineIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Socials Information</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500 lining-nums "><big className='text-black text-base'>Page</big></dd> 
                <dd className="mt-2 ml-16 text-base text-gray-500 lining-nums "><big className='text-black text-base'>Instagram </big> </dd> 
                <dd className="mt-2 ml-16 text-base text-gray-500 lining-nums "><big className='text-black text-base'>Facebook </big> </dd> 
              </div>
              </>
              ])} */}

          

  
           
          {collaborator?.scheduleDays?.mondayToFriday ? ( 
            <>
             <div  className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white">
                    <FaCalendarDay className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-sans font-medium text-gray-900">Schelude Information</p>
                </dt>
                <dd className="mt-2 ml-16 text-base font-body text-gray-500 lining-nums "> Monday <big className='text-black text-base'> To </big> Friday </dd> 
               
              </div>
            </>
           ):(
            <></>
           )}
           

           { collaborator?.scheduleDays?.mondayToSaturday ? ( 
            <>
               <div  className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white">
                    <FaCalendarDay className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg font-sans leading-6 font-medium text-gray-900">Days Information</p>
                </dt>
                <dd className="mt-2 ml-16 text-base font-body text-gray-500 lining-nums ">Monday <big className='text-black text-base'> To</big> Saturday</dd> 
               
              </div>
            </>
           ):(
            <></>
           )}
           

           { collaborator?.scheduleDays?.sunday ? ( 
            <>
               <div  className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white">
                    <FaCalendarDay className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg font-sans leading-6 font-medium text-gray-900">Special Day Information</p>
                </dt>
                <dd className="mt-2 ml-16 text-body text-gray-500 lining-nums "> Work 
                <big className='text-black text-base '> on  </big> 
                 Sunday 
                </dd> 
                 
              </div>
            </>
           ):(
            <></>
           )}
           
          
            <>
               <div  className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white">
                    <FaClock className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg font-sans leading-6 font-medium text-gray-900">Schelude Infomation</p>
                </dt>
                <dd className=" mt-2 text-black text-base lining-nums ml-16 grid grid-flow-col auto-cols-max ">
                  {collaborator?.scheduleDays?.hoursMToSInit} 
                  <big className=' ml-3 font-body text-base text-gray-500 '> AM </big>
                  <p className='mx-2'> at </p>
                  {collaborator?.scheduleDays?.hoursMToSExit}
                  <big className='ml-3 font-body text-base text-gray-500'> PM </big>
                </dd> 
                 
              </div>
            </>
          
 
        

    </dl>
        </div>
      </div>
    </div>
            

     
  </>


       {/* ***** SERVICES SOLICITUDE ****** */} 
             {/* **********************call********************* */}
             <> 
             {user ? (
              <>
    <div className="hidden  mt-1 h-6 w-5/5 mx-2   inset-0  items-center justify-center">
        <button
          type="button"
          onClick={openModalAppointment}
          title='SET A SERVICE' className= " px-4 py-1 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          <FaCalendarDay className='h-8 w-8  ' />
          
        </button>
        
      </div>

      <Transition appear show={isOpenAppointment} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalAppointment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Need Information
                  </Dialog.Title>
                  <div className="mt-2 max-w-md ">
                  
  <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
    <div className="grid grid-flow-col auto-cols-max">
      <img
        className="h-16 w-16 object-cover rounded-full hover:scale-150"
        src={user.avatar && user.avatar.url}
        alt={user && user.name}
      />
      <div className="mx-5 grid grid-flow-row auto-rows-max">
        <p className="text-lg text-black font-semibold">{user && user.name}</p>
      </div>
    </div>
    <form onSubmit={handleSubmitAppointment}>
      {/* <textarea
        className="hidden mx-6 border-red-800 my-4 popover-body border-2 border-solid text-md"
        placeholder="Collaborator ID"
        id="collaboratorId"
        type="hidden"
        name="collaboratorId"
        value={collaboratorId}
        onChange={(e) => setCollaboratorId(e.target.value)}
        // style={styles.textarea}
      ></textarea> */}
        <input type="hidden" name="userId" value={userId} />
      <label className="block mb-2">
        Name:
        <input
          name="name"
          value={name}
          placeholder= {user.name}
          onChange={(e) => setName(e.target.value)}
          className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </label>
      <label className="block mb-2">
        Description:
        <textarea
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >

        </textarea>
      </label>
      <label className="block mb-2">
        Address:
        <input
          name="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        ></input>
      </label>
      <label className="block mb-2">
        Start Time:
        <input
          type="datetime-local"
          name="startTime"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </label>
      <label className="block mb-2">
        End Time:
        <input
  type="datetime-local"
  name="endTime"
  value={endTime}
  onChange={(e) => setEndTime(e.target.value)}
  className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
/>
</label>
<label className="block mb-2">
Note:
<textarea
name="noteGuide"
value={noteGuide}
onChange={(e) => setNoteGuide(e.target.value)}
className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
></textarea>
</label>
<label className="block mb-2">
Images:
<input
       type="file"
       name="imagesServices"
       multiple
       onChange={handleImageAppointment}
       className="block w-full mt-2 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
     />
</label>
<button
     type="submit"
     className="inline-flex justify-center rounded-md border border-transparent bg-blue-200 ml-8 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
   >
Submit
</button>
</form>
</div>
 
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModalAppointment}
                    >
                      Cancel!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      </>
             )
             : 
             <>
              <div className='hidden py-4'>
      <Link
          type="button"
          to='/login'          
          className=
          "flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 px-8 py-3 text-base font-medium text-white hover:bg-red-700 md:py-4 md:px-10 md:text-lg"
        >
          Login to set Appointment
        </Link>
    </div>
             </>
    }
    </>
 
    <div className="hidden max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div style={{ height: 500 }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={(event, start, end, isSelected) => {
            const color = event.collaborator === collaboratorId ? 'green' : 'blue';
            return { style: { backgroundColor: color } };
          }}
          onSelectEvent={(event) => {
            setSelectedAppointment(event);
          }}
          style={{ margin: '0 auto' }}
        />
      </div>
    </div>

    <div className="hidden max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
  <p>HOLA MUNDO</p>
  <AppointmentsSlider  />

  </div>

</>
  )
      }
export default CollaboratorsDetails