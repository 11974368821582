import { collaboratorAuthReducer, collaboratorReducer, forgotPasswordCollaboratorReducer, allCollaboratorsReducer, collaboratorDetailsReducer, newReviewReducer, reviewReducer, appointmentReducer } from '../reducers/collaboratorReducers'

import { authReducer, userReducer, forgotPasswordReducer, allUsersReducer, userDetailsReducer } from '../reducers/userReducers'


import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import Cookies from 'js-cookie';
import { loadUser } from '../actions/userActions';
import { loadCollaborator } from '../actions/collaboratorActions';
import { useDispatch } from 'react-redux';



const reducer = combineReducers({
 
  auth: authReducer,
  appointmentReducer: appointmentReducer,
  user: userReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  forgotPassword: forgotPasswordReducer,
  collaboratorAuth:collaboratorAuthReducer,
  collaborator: collaboratorReducer,
  allCollaborators: allCollaboratorsReducer,
  collaboratorDetails: collaboratorDetailsReducer,
  collaboratorForgotPassword: forgotPasswordCollaboratorReducer,
  newReview: newReviewReducer,
  review: reviewReducer

})


// Obtener el token guardado en las cookies
const token = Cookies.get('token');

// Obtener los datos del usuario guardados en localStorage
const userInfo = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

// Obtener los datos del Collaborators guardados en localStorage
 const collaboratorInfo = localStorage.getItem('collaboratorInfo')
 ? JSON.parse(localStorage.getItem('collaboratorInfo'))
 : null;

// Actualizar el initialState
const initialState = {

   collaboratorAuth:{
     isAuthenticatedCollaborator: token ? true : false,
     token: token,
     collaborator: collaboratorInfo || null,
   },
  auth: {
    isAuthenticated: token ? true : false,
    token: token,
    user: userInfo,
  },
  cart: {
    cartItems: [],
    shippingInfo: {},
  },
  
};



// Crear el store
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

// Cargar el usuario al iniciar la aplicación

if (token) {
  store.dispatch(loadUser());
  store.dispatch(loadCollaborator());
}
export default store;