import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createAppointment, deleteAppointment, getAppointments, updateAppointment } from '../../actions/appointmentActions';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { handleFileInputChange } from '../Utils/FormUtils';

const localizer = momentLocalizer(moment);

const Appointments = () => {
  const collaboratorDetails = useSelector((state) => state.collaboratorDetails);
  const { loadingC, collaborator, error} = collaboratorDetails;

  const [appointments, setAppointments] = useState([]);
  const [formData, setFormData] = useState({
    collaboratorId: '',
    description: '',
    address: '',
    name: '',
    startTime: '',
    endTime: '',
    status: '',
    noteGuide: '',
  });

  useEffect(() => {
    getAppointments().then((data) => {
      setAppointments(data);
    });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    for (let i = 0; i < formData.imagesServices.length; i++) {
      form.append("imagesServices", formData.imagesServices[i]);
    }
    form.append("collaboratorId", formData.collaboratorId);
    form.append("description", formData.description);
    form.append("address", formData.address);
    form.append("name", formData.name);
    form.append("startTime", formData.startTime);
    form.append("endTime", formData.endTime);
    form.append("status", formData.status);
    form.append("noteGuide", formData.noteGuide);
    
    try {
      const response = await axios.post("/api/v1/appointment", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      // Add the newly created appointment to the appointments state
      setAppointments((prevState) => [...prevState, response.data]);
      // Clear the form data
      setFormData({
        collaboratorId: '',
        description: '',
        address: '',
        name: '',
        startTime: '',
        endTime: '',
        status: '',
        noteGuide: '',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id, newData) => {
    updateAppointment(id, newData).then((data) => {
      setAppointments((prevState) =>
        prevState.map((appointment) => (appointment._id === data.appointment._id ? data.appointment : appointment))
      );
    });
  };

  const handleDelete = (id) => {
    deleteAppointment(id).then((data) => {
      setAppointments((prevState) => prevState.filter((appointment) => appointment._id !== data.appointment._id));
    });
  };

  return (
    <div>
      <h1>Add Appointment</h1>
      <form onSubmit={handleSubmit}>
         <label>
          Collaborator:
          <select name="collaboratorId" value={collaborator.id} onChange={handleInputChange}>
           
              <option key={collaborator.id} value={collaborator.id}>
                {collaborator.name}
              </option>
            
          </select>
        </label> 
        <label>
          Description:
          <textarea name="description" value={formData.description} onChange={handleInputChange} />
        </label>
        <label>
          Date:
          <input type="datetime-local" name="date" value={formData.date} onChange={handleInputChange} />
        </label>
        <label>
          Location:
          <input type="text" name="location" value={formData.location} onChange={handleInputChange} />
        </label>
        <label>
          Images:
          <input type="file" name="images" multiple onChange={handleFileInputChange} />
        </label>
        <button type="submit">Save</button>
      </form>
    </div>
  ); }

  export default Appointments